.on {
  background-color: #40b44c;
}

.off {
  background-color: #69768b;
}

.start {
  position: absolute;
  transform: translateX(-10%);
  transition: all 300ms;
}

.end {
  position: absolute;
  transform: translateX(10%);
  transition: all 300ms;
}

.appToggle {
  border: 1px solid #eaecf3;
  position: relative;
}

.sm {
  border-radius: 12px;
  width: 55px;
  height: 35px;

  .button {
    width: 50% !important;
    border-radius: 8px;
    font-size: 10px !important;
    padding: 4px 0px !important;
  }

  .start {
    left: 8px;
    transform: translateX(-8%);
  }

  .end {
    right: 8px;
    transform: translateX(8%);
  }
}

.md {
  border-radius: 12px;
  width: 62px;
  height: 38px;

  .button {
    width: 50% !important;
    border-radius: 8px;
    padding: 5px 0px !important;
  }

  .start {
    left: 10px;
    transform: translateX(-10%);
  }

  .end {
    right: 10px;
    transform: translateX(10%);
  }
}
