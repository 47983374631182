.indicator {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.activeColor {
  background-color: #3ecd7b;
}

.inactiveColor {
  background-color: #a8b2c2;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: blink 1.5s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
