//.color01 {
//  border: solid 2px #f44336;
//  background-color: #f443361c;
//  color: #f44336;
//}
//.color02 {
//  border: solid 2px #9c27b0;
//  background-color: #9c27b01c;
//  color: #9c27b0;
//}
//.color03 {
//  border: solid 2px #3f51b5;
//  background-color: #3f51b51c;
//  color: #3f51b5;
//}
//.color04 {
//  border: solid 2px #2196f3;
//  background-color: #2196f31c;
//  color: #2196f3;
//}
//.color05 {
//  border: solid 2px #00bcd4;
//  background-color: #00bcd41c;
//  color: #00bcd4;
//}
//.color06 {
//  border: solid 2px #009688;
//  background-color: #0096881c;
//  color: #009688;
//}
//.color07 {
//  border: solid 2px #8bc34a;
//  background-color: #8bc34a1c;
//  color: #8bc34a;
//}
//.color08 {
//  border: solid 2px #ffc107;
//  background-color: #ffc1071c;
//  color: #ffc107;
//}
//.color09 {
//  border: solid 2px #795548;
//  background-color: #7955481c;
//  color: #795548;
//}
//.color10 {
//  border: solid 2px #607d8b;
//  background-color: #607d8b1c;
//  color: #607d8b;
//}

.color01 {
  border: solid 2px #a92e25;
  background-color: rgba(244, 67, 54);
  color: #fff;
}
.color02 {
  border: solid 2px #6c1a79;
  background-color: rgba(156, 39, 176);
  color: #fff;
}
.color03 {
  border: solid 2px #2a367a;
  background-color: rgba(63, 81, 181);
  color: #fff;
}
.color04 {
  border: solid 2px #1569a9;
  background-color: rgba(33, 150, 243);
  color: #fff;
}
.color05 {
  border: solid 2px #008191;
  background-color: rgba(0, 188, 212);
  color: #fff;
}
.color06 {
  border: solid 2px #005b51;
  background-color: #009688;
  color: #fff;
}
.color07 {
  border: solid 2px #698d36;
  background-color: rgba(139, 195, 74);
  color: #fff;
}
.color08 {
  border: solid 2px #c09004;
  background-color: #ffc107;
  color: #fff;
}
.color09 {
  border: solid 2px #795548;
  background-color: rgb(121, 85, 72);
  color: #fff;
}
.color10 {
  border: solid 2px #607d8b;
  background-color: rgb(96, 125, 139);
  color: #fff;
}

.overlay-active {
  outline: 2px solid #000000;
  border: 2px solid #ffffff !important;
}

.color01b {
  background-color: #f44336;
}
.color02b {
  background-color: #9c27b0;
}
.color03b {
  background-color: #3f51b5;
}
.color04b {
  background-color: #2196f3;
}
.color05b {
  background-color: #00bcd4;
}
.color06b {
  background-color: #009688;
}
.color07b {
  background-color: #8bc34a;
}
.color08b {
  background-color: #ffc107;
}
.color09b {
  background-color: #795548;
}
.color10b {
  background-color: #607d8b;
}
