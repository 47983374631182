.input-field ::placeholder {
  color: #eaecf3;
}

@media (max-width: 480px) {
  .input-field {
    width: 45px;
    margin: 0 5px 0 5px;
  }
  .input-field:first-child {
    margin-left: -20px;
  }
}
