.appBanner {
  border-radius: 8px;
}

.yellow {
  background-color: #fffff8;
  border: 0.5px solid #f8fa9c;
}

.lightRed {
  background: #fff9f8;
  border: 0.5px solid #fee5db;
}

.brown {
  background: #fffcf8;
  border: 0.5px solid #fac99c;
}
