.infoCardContainer {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 60px 200px rgba(75, 75, 78, 0.2);
  height: 100%;
}

.todayBackground {
  border-radius: 8px;
  background-color: #f7f8ee;
}
