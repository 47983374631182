.miniCalendarToggler {
  border: 1px solid #eaecf3;
  border-radius: 12px;
}

.title {
  width: 110;
  white-space: nowrap;
  overflow: "hidden";
  text-overflow: ellipsis;
  border-radius: 4px;
  margin-bottom: 2px;
  padding: 1px 5px;
}
