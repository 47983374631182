.bulkAppToggle {
  border: 1px solid #eaecf3;
  border-radius: 12px;
  height: 40px;
}

.both {
  width: 8px;
  height: 30px;
}

.gray {
  background-color: #69768b;
}

.green {
  background-color: #40b44c;
}

.button {
  border-radius: 8px;
}
