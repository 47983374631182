.registrationThrough {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #69768b;
}

.appImage {
  cursor: pointer;
}
