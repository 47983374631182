.searchWrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #eaecf3;
  border-radius: 12px;
  background-color: #ffffff;
  .search-input,
  .search-input:focus {
    border-style: none;
    outline: none;
    border-radius: 16px;
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.dropdown-menu {
  position: absolute;
  width: 90%;
  background-color: #ffffff;
  border: 1.5px solid #f5f6f7;
  box-shadow: 0px 6px 16px rgba(193, 193, 193, 0.25);
  border-radius: 8px;
  z-index: 5;
  list-style-type: none;
  padding: 0;
  margin-top: 5px;

  li {
    cursor: pointer;
    padding: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #69768b;
    &:hover {
      background-color: rgba(221, 221, 221, 0.137);
    }
  }
}

.overflow {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 30vh;
}

.overflow::-webkit-scrollbar {
  width: 8px;
  height: 8px !important;
}

.overflow::-webkit-scrollbar-thumb {
  background-color: #eaecf3;
  border-radius: 20px;
}
