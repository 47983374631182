.iconBackground {
  border-radius: 6px;
  background-color: #fcf4f4;
}

.dashContainer {
  border-radius: 8px;
  border: 2px dashed #69768b;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
