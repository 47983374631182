.app-button {
  width: 100%;
  transition-property: background-color;
  transition-duration: 200ms;
  border: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appIconButton {
  width: 40px !important;
  padding: 0 !important;
}

.app-button-disabled {
  background-color: rgb(182, 182, 182) !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.app-button-small {
  border-radius: 12px;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 6px;
}

.app-button-extra-small {
  border-radius: 11px !important;
  font-size: 12px;
  padding: 0px 15px;
  border-radius: 6px;
  height: 35px;
  width: 35px !important;
}

.app-button-large {
  border-radius: 12px;
  padding: 8px 15px;
}

.app-button-medium {
  border-radius: 12px;
  padding: 9px;
  font-weight: 400;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
}

.app-button-blue {
  background-color: #2f2a89;
  color: #fff;
  box-shadow: -4px 10px 22px rgba(47, 42, 137, 0.42);
  &:hover {
    background-color: lighten(#2f2a89, 5%);
  }
}

.app-button-red {
  background-color: #bf4545;
  color: #fff;
  box-shadow: -4px 10px 22px rgba(191, 69, 69, 0.35);
  &:hover {
    background-color: lighten(#bf4545, 5%);
  }
}

.app-button-transparent {
  background-color: #ffffff00;
  color: #56519e;
  &:hover {
    text-decoration: underline;
  }
}

.app-button-transparent-with-border {
  background: #fff;
  color: #69768b;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
  border: 1px solid #eaecf3;
}

.app-button-gray {
  background-color: #a6adb9;
  color: #fff;
  box-shadow: -4px 10px 22px rgba(204, 204, 204, 0.42);
  &:hover {
    background-color: lighten(#a6adb9, 5%);
  }
}

.app-button-light {
  background-color: #69768b;
  color: #fff;
  box-shadow: -4px 10px 22px rgba(204, 204, 204, 0.42);
}

.manual-border-shadow {
  box-shadow: -4px 10px 22px rgba(191, 69, 69, 0.35);
}

.default-cursor {
  cursor: default !important;
}

.indicator {
  background-color: #d84560;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}
