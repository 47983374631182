@media (max-width: 508px) {
  .filter {
    justify-content: space-between !important;
    margin-top: 16px;
  }
}

@media (max-width: 508px) {
  .FiltersView {
    width: 100% !important;
  }
}
