.ceb-customer {
  border-left: 3px solid #f1f1ff;
}

.leco-customer {
  border-left: 3px solid #fdf8cd;
}

.disable {
  background-color: #f8f8f8;
  opacity: 50%;
}
