.indicatorFirst {
  width: 6px;
  height: 6px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 52px;
    background-color: #b9b6cb;
    display: block;
  }
}

.indicator {
  width: 6px;
  height: 6px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -52px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 52px;
    background-color: #b9b6cb;
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 52px;
    background-color: #b9b6cb;
    display: block;
  }
}

.indicatorLast {
  width: 6px;
  height: 6px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -52px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 52px;
    background-color: #b9b6cb;
    display: block;
  }
}
