.deviceListCardContainer {
  box-shadow: 0px 60px 120px rgba(75, 75, 78, 0.2);
  border-radius: 8px;
  background-color: #fff;
}

.deviceName {
  font-weight: 500;
  font-size: 14px;
  color: #383941;
}

.textTruncate {
  width: 50px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
