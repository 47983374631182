.infoCardContainer {
  background-color: #ffffff;
  border-radius: 8px;
}

.background1 {
  background-color: #f7f8ee;
  border-radius: 8px;
  height: 100%;
  padding: 10px 20px;
}
