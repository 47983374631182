.deviceTitle {
  font-weight: 500;
  font-size: 14px;
  color: #383941;
}

.deviceValue {
  font-weight: 400;
  font-size: 14px;
  color: #69768b;
}

.socket {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 31px 94px 0px rgba(38, 51, 77, 0.1);
}
