.registrationStepsHeading {
  font-weight: 500;
  font-size: 32px;
  color: #383941;
}

.registrationStepsSubHeading {
  font-weight: 400;
  font-size: 16px;
  color: #69768b;
}

.stepHeading {
  font-weight: 500;
  font-size: 20px;
  color: #383941;
}

.step {
  font-weight: 300;
  font-size: 14px;
  color: #69768b;
}
