.notFoundHeading {
  font-weight: 600;
  color: #383941;
}
.notFoundParagraph {
  color: #69768b;
}

.arrowBtton {
  border-radius: 12px;
  width: 40px;
}
