.socketTypes {
  background-color: #fffff8;
  border-radius: 8px;
  border: 1px solid #f8fa9c;
}

.unlinkCard {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 31px 94px 0px rgba(38, 51, 77, 0.1);
}
