.device-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
}

.device-name {
  font-weight: 500;
  font-size: 14px;
  color: #383941;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.device-energy {
  font-weight: 400;
  font-size: 12.8356px;
  color: #69768b;
}

.device-path {
  font-weight: 400;
  font-size: 12px;
  color: #69768b;
}

.selected {
  background-color: #2f2a89;
}

.selectedFontColor {
  color: #fff;
}
