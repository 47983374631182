.container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 60px 120px rgba(38, 51, 77, 0.05);
  height: 100vh;

  .settingItemContainer {
    border-right: 2px solid #f5f6f7;
    height: 100vh;

    .settingItems {
      padding: 40px 25px 40px 10px;
    }
  }

  .settings {
    padding: 36px 10px;
  }

  .hr {
    border-bottom: 1px solid #f3f3f3;
    margin-top: 5px;
  }
}
