$theme-colors: (
  "primary": #2f2a89,
  "dark": #383941,
  "dark-2": #505662,
  "light": #69768b,
  "background": #f5f5f5,
  "white": #fff,
  "gray": #6f757e,
  "icon": #b9b6cb,
  "danger": #bf4545,
  "red": #d84560,
  "icon-2": #ededf7,
  "icon-3": #f0fbfa,
  "icon-4": #ecfaed,
  "green": #29bcc6,
  "blue": #6e7fd4,
  "gray-2": #e2e2e2,
  "gray-3": #f9f9f9,
  "gray-4": #536b82,
  "icon-5": #fff8ea,
  "icon-6": #faecec,
  "activeBg-1": #f4edf6,
  "activeBg-2": #eef8f6,
  "activeBg-3": #f6eded,
  "activeBg-4": #eef6f8,
  "activeBg-5": #f4f7ed,
  "activeBg-6": #f3f2ff,
  "activeBg-7": #f2f8ee,
  "activeBg-8": #f5eded,
  "activeBg-9": #f1f8f8,
  "activeBg-10": #f0edf5,
  "activeBg-11": #f6f1f8,
  "activeBg-12": #f7f8f1,
  "activeBg-13": #f8f6ee,
  "activeBg-14": #edf4f5,
  "activeBg-15": #f5f1ed,
  "background-2": #f7f7fb,
  "background-3": #d84560,
  "yellow-1": #f7f8ee,
  "purple-1": #eeedf7,
  "gray-5": #f8f8f8,
  "blue-2": #e7f4f6,
  "green-2": #eef8ee,
  "green-3": #29cc39,
  "gray-6": #f6f8ec,
  "gray-7": #f4f4f4,
  "blue-3": #302b89,
  "dark-3": #252724,
  "gray-8": #f6f6f6,
  "gray-9": #eaecf3,
);

@import "~bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
