.device-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8.5571px;
}

.device-icon-large {
  width: 60px;
  height: 60px;
}

.device-icon-small {
  width: 40px;
  height: 40px;
}

.device-name {
  font-weight: 500;
  font-size: 20px;
  color: #505662;
}

.large {
  display: block;
}

.small {
  display: none;
}

@media only screen and (max-width: 630px) {
  .large {
    display: none;
  }

  .small {
    display: block;
  }
}
