.switch {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 43px;
  box-shadow: -4px 10px 22px 0px rgba(47, 42, 137, 0.42);

  .button {
    border-radius: 100%;
  }

  .on {
    background-color: #fff;
    position: absolute;
    right: 8px;
    transform: translateX(10%);
    transition: all 300ms;
  }

  .off {
    background-color: #d4d7e1;
    position: absolute;
    left: 8px;
    transform: translateX(-10%);
    transition: all 300ms;
  }
}

.lg {
  width: 65px;
  height: 40px;

  .button {
    width: 24px;
    height: 24px;
  }
}

.md {
  width: 53px;
  height: 30px;

  .button {
    width: 20px;
    height: 20px;
  }
}

.sm {
  width: 44px;
  height: 24px;

  .button {
    width: 16px;
    height: 16px;
  }
}

.onBackground {
  background: #2f2a89;
}

.offBackground {
  border: 1px solid #d4d7e1;
  background: #fff;
}
