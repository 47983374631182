.loginWrapper {
  background-color: #ffffff;
}

.title {
  font-weight: 500;
  font-size: 32px;
  color: #383941;
}

.subTitle {
  font-weight: 400;
  font-size: 16px;
  color: #69768b;
}

@media (min-width: 991px) {
  .loginWrapper {
    background: linear-gradient(90deg, #ffffff 48%, #fbfbff 48%);
  }
}

@media (max-width: 480px) {
  .title {
    font-weight: 400;
    font-size: 24px;
  }

  .subTitle {
    font-size: 14px;
  }
}
