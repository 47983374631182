.title {
  font-weight: 400;
  font-size: 16px;
  color: #69768b;
}

.resendAttempts {
  font-weight: 400;
  font-size: 14px;
  color: #69768b;
}

.errorTitle {
  font-weight: 400;
  font-size: 14px;
  color: #d74242;
}

.resend {
  cursor: pointer;
  text-decoration: underline;
  color: #2f2a89;
}
.resendWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
