.appType {
  border-radius: 12px;
  border: 1px solid #eaecf3;
}

.textArea {
  border-radius: 8px;
  border-color: #eaecf3;
  min-height: 15vh;
  &::placeholder {
    padding: 6px 15px;
  }
}
