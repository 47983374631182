.title {
  font-family: "Poppins";
  color: #69768b;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
}

.label {
  color: #383941;
  padding: 3px 7px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.border-radius-left {
  border-radius: 9.7px 0 0 9.7px;
}

.border-radius-right {
  border-radius: 0 9.7px 9.7px 0;
}

.border-radius {
  border-radius: 9.7px;
}

.bold {
  font-weight: 700;
  font-size: 12px;
}

.normal {
  font-weight: 600;
  font-size: 12px;
}
