.overflow {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 22vh;
}

.overflow::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.overflow::-webkit-scrollbar-thumb {
  background-color: #eaecf3;
  border-radius: 20px;
}
