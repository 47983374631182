.datePicker {
  z-index: 1000 !important;
  position: absolute;
  right: 50%;
  transform: translateX(40%);

  @media screen and (min-width: 992px) {
    transform: translateX(0);
    right: 0;
  }
}

.isInput {
  transform: translateX(0) !important;
  right: 0 !important;
}

.isInputCalendar {
  transform: translateX(80%) !important;
  right: 0 !important;
}
