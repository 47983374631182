.appTableContainer {
  .table {
    width: 100%;
    background-color: #fdfdfd;
    border: solid 1px #f5f6f7;
    position: relative;
    //overflow-x: auto;
    height: 1000px;
  }

  .scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scroll::-webkit-scrollbar-track {
    background: rgb(233, 233, 233);
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: rgb(187, 187, 187);
    border-radius: 20px;
  }
}

.toolbar {
  display: flex;
  width: (150 * 8)+30px;
  margin: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  background-color: white;
}

.today {
  position: absolute;
  height: 4px;
  left: 150px;
  top: 60 * 5+30px;
  z-index: 1;
  background-color: #ff8787;
  transition: all 200ms ease-in;

  #today-indicator-scroll-to-div {
    position: relative;
  }

  &::before {
    content: "";
    width: 15px;
    border-radius: 50%;
    height: 15px;
    background: #ff8787;
    position: absolute;
    top: -6px;
    left: -2px;
  }
}

.todayDeviceView {
  width: 145px;
}

.todayDateView {
  width: 150px;
}

.leftHeaderCellContainer {
  position: sticky;
  z-index: 1;
  width: 100px; /* pseudo width to keep sticky working */
  left: 0;
}

.topHeaderCellContainer {
  position: sticky;
  z-index: 1;
  top: -15px;
  left: 0;
}
