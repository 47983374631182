.controllerCardContainer {
  box-shadow: 0px 60px 120px rgba(75, 75, 78, 0.2);
  border-radius: 8px;
  background-color: #fff;
}

.unLinkControllerCardContainer {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
}

.generalController {
  background: #eef3f8;
  color: #7e9abb;
}
