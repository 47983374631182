.highestPowerUsageDevicesContainer {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
