.label {
  border-radius: 8px;
  color: #383941;
  border-radius: 5px;
  border: 2px solid var(--Btn-Primary, #2f2a89);
  background: rgba(42, 39, 216, 0.05);
}

.time {
  font-size: 8px;
  padding-left: 4px;
}
