.title {
  width: 110;
  white-space: nowrap;
  overflow: "hidden";
  text-overflow: ellipsis;
  border-radius: 4px;
  margin-bottom: 2px;
  padding: 1px 5px;
}

.miniCalendarToggler {
  border: 1px solid #eaecf3;
  border-radius: 12px;
}

@media (max-width: 878px) and (min-width: 769px) {
  .nowBtn {
    margin-top: 15px !important;
  }
}

@media (max-width: 628px) {
  .nowBtn {
    margin-top: 15px !important;
  }
}

@media (max-width: 1026px) {
  .miniCalendarToggler {
    margin-top: 15px !important;
  }
}

@media (max-width: 1090px) {
  .schedule-log-view-change-btn {
    margin-top: 15px !important;
  }
}

.calendarContent {
  overflow-y: hidden;
  height: auto;
  overflow-x: auto;
}
