.spacePathViewBreadcrumbContainer {
  border-radius: 12px;
  background: #f8f8fc;
}

.greaterThan {
  color: #69768b;
}

.breadCrumbPath {
  color: #69768b;
  font-size: 13px;
  font-weight: 400;
}

.selectedBreadCrumbPath {
  border-radius: 12px;
  background: #2f2a89;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}
