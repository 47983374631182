.cell {
  border-bottom: solid 1px #e7e8e9;
  border-right: solid 1px #e7e8e9;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  user-select: none;
  input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    z-index: 50px;
    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: transparent;
      color: black;
    }
  }
}

.headerCell {
  font-weight: 700;
  color: #2e2e2e;
  font-size: 12px;
  text-align: center;
}
