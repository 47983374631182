.realTimePowerUsageChart {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.spaceName {
  color: #383941;
  font-size: 14px;
  font-weight: 500;
}
