.device-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
}

.device-name {
  font-weight: 500;
  font-size: 14px;
  color: #383941;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.device-outlet {
  font-weight: 400;
  font-size: 12px;
  color: #69768b;
}

@media only screen and (max-width: 568px) {
  .overflow-horizontal {
    overflow-x: auto;
    width: 500px;
  }
}

.limitationWrapper {
  border-radius: 8px;
  border: 1px solid #d4d7e1;
  background: #fff;
  box-shadow: -4px 10px 22px 0px rgba(160, 158, 214, 0.42);
  padding: 4px;
  cursor: pointer;
}

.deviceCardGradient {
  border-radius: 8px;
  background: linear-gradient(
    270deg,
    rgba(232, 72, 4, 0.08) 0.72%,
    rgba(217, 217, 217, 0) 52.52%
  );
}

.deviceCardOpacity {
  opacity: 0.4;
}
