.title {
  font-family: "Poppins";
  color: #69768b;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
}

.label {
  border-radius: 9.7px;
  color: #383941;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
}

.bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.normal {
  font-weight: 600;
  font-size: 14px;
}

.small {
  font-weight: 600;
  font-size: 12px;
}
