.timeExclude {
  border: 2px solid #ec7575;
  border-radius: 5px;
}

.timeWarning {
  border: 2px solid #e8c304;
  border-radius: 5px;
}

.limitationWrapper {
  border-radius: 8px;
  border: 1px solid #d4d7e1;
  background: #fff;
  box-shadow: -4px 10px 22px 0px rgba(160, 158, 214, 0.42);
  padding: 4px;
}
