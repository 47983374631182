.side-drawer {
  position: fixed;
  top: 76px;
  max-width: 500px;
  transform: translate(-150%);
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 0px 27px rgba(221, 221, 221, 0.25);
  border-radius: 12px;
  overflow-y: auto;
}

.side-drawer::-webkit-scrollbar {
  width: 8px;
}

.side-drawer::-webkit-scrollbar-thumb {
  background-color: #ededed;
  border-radius: 20px;
}

.side-drawer.open {
  transform: translateY(0);
  animation: open-animation 0.8s forwards;
}

.side-drawer.close {
  transform: translateY(0);
  animation: close-animation 0.8s forwards;
}

@keyframes open-animation {
  from {
    transform: translateY(-150%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes close-animation {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-150%);
  }
}

@media (min-width: 992px) {
  .side-drawer {
    top: 77px;
    right: 10px;
  }
}
