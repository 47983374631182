.device-item-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
}

.smartOutlet {
  background: #eef3f8;
  color: #7e9abb;
}

.allScroll {
  cursor: all-scroll;
}
