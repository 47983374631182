.appSelectInput {
  background: #ffffff;
  border: 1px solid #eaecf3;
  width: 100%;
  padding: 0 6px;
  &.hasDanger {
    border: 1px solid #d74242ed;
  }
}

.opacity {
  opacity: 0.5;
}

@media (max-width: 480px) {
  .placeholder-text {
    font-size: 3.5vw;
  }
}
