.notification-card {
  border-radius: 8px 0px 0px 0px;
  background: linear-gradient(
    90deg,
    rgba(216, 95, 69, 0.05) 0.75%,
    rgba(217, 217, 217, 0) 99.93%
  );
}

.noNotifications {
  height: 200px;
}
