.selected {
  .circle {
    background-color: #2f2a89;
  }

  .stepName {
    color: #2f2a89;
  }
}

.unselected {
  .circle {
    background-color: #69768b;
  }

  .stepName {
    color: #69768b;
  }
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash {
  border: 1px solid #d9d9d9;
}
