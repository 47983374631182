.filterSidePanelTitle {
  font-weight: 500;
  font-size: 18px;
  color: #383941;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.side-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  z-index: 110;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 27px rgba(221, 221, 221, 0.25);
  border-radius: 8px 0px 0px 8px;
  overflow-y: auto;
  @media screen and (max-width: 552px) {
    width: 100%;
  }
}

.side-drawer::-webkit-scrollbar {
  width: 8px;
}

.side-drawer::-webkit-scrollbar-track {
  background: rgb(128, 128, 128);
}

.side-drawer::-webkit-scrollbar-thumb {
  background-color: rgb(66, 66, 66);
  border-radius: 20px;
}

.side-drawer.open {
  transform: translateX(0);
}

.close-button {
  margin: 10px;
}
