.title {
  color: #69768b;
  font-weight: 400;
  font-size: 16px;
}

.value {
  color: #383941;
  font-weight: 400;
  font-size: 16px;
}
