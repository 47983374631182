.vertical-line {
  border-left: 2px solid #eaecf3;
  height: 90vh;
}

.horizontal-line {
  border: 1.5px solid #eaecf3;
}

.overflow {
  overflow-y: auto;
  max-height: 65vh;
}

.overflow::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.overflow::-webkit-scrollbar-track {
  background: #ffffff;
}

.overflow::-webkit-scrollbar-thumb {
  background-color: #eaecf3;
  border-radius: 20px;
}

@media screen and (max-width: 446px) {
  .cancel-button-margin-top {
    margin-top: 20px;
  }
}

.instruction {
  border: 1px solid #f8fa9c;
  background-color: #fffff8;
  border-radius: 50%;
}
