.deviceCardContainer {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.12);
}

.smartController {
  background: #eef3f8;
  color: #7e9abb;
}
