.button {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 12px;
  background-color: #ffffff;
}

.badge {
  position: relative;
  top: 7px;
  left: -8px;
  font-size: 9px;
  background-color: #2f2a89;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
