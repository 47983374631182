.tabButtonWhite {
  background-color: #eaecf3;
  color: #69768b;
}

.lightBlue {
  background-color: #6d7ed2;
  color: #fff;
}

.lightGreen {
  background-color: #29bcc6;
  color: #fff;
}

.purpleColor {
  background-color: #2f2a89;
  color: #fff;
}

.tabButtonGroup {
  height: 40px;
  width: 100%;
  .tabButton:first-child {
    border-radius: 0.75rem 0 0 0.75rem;
  }
  .tabButton:last-child {
    border-radius: 0 0.75rem 0.75rem 0;
  }
  .tabButton:not(:first-child, :last-child) {
    border-radius: 0 0 0 0;
  }

  .tabButton {
    padding: 5px 10px;
    border: 1px solid #eaecf3;
    cursor: pointer;
    font-weight: 500;
    font-family: "Poppins";
    box-shadow: 0px 8px 17px rgba(47, 42, 137, 0.2);
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
