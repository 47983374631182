.container {
  padding: 30px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0 60px 120px 0 rgba(38, 51, 77, 0.05);
  width: 100%;
}

.containerBoxShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.eventContainer {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.03);
}

.scrollableArea {
  width: 376px;
  max-height: 295px;
  overflow: auto;
}

.scrollableArea::-webkit-scrollbar {
  width: 8px;
}

.scrollableArea::-webkit-scrollbar-track {
  background: #efefef;
}

.scrollableArea::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 20px;
}

.deviceCategoryContainer {
  border-radius: 8px;
}
