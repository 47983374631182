.white {
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(224, 224, 224, 0.25);
  color: #69768b;
}

.purple {
  background-color: #ffffff;
  border: 1px solid #2f2a89;
  color: #2f2a89;
  box-shadow: 0px 3px 15px rgba(47, 42, 137, 0.22);
}

.switch {
  font-weight: 400;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 12px;
  cursor: pointer;
  height: 100%;
}
