.space-selector-dropdown-select {
  background: #ffffff;
  border: 1px solid #eaecf3;
  border-radius: 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.space-selector-row {
  padding: 0 5px;
  cursor: pointer;
}

.d-arrow {
  transition: transform 200ms ease-in;
}

.dropdownContainer {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(193, 193, 193, 0.25);
  border-radius: 8px;
  padding: 20px 30px;
  overflow: auto;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 1000;
}
