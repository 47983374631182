.appBanner {
  font-size: 14px;
  color: #69768b;
  text-align: center;
  font-weight: 400;
  border-radius: 12px;
}

.yellow {
  background: #fffef5;
  border: 1px solid #fff9c0;
}

.lightBlue {
  background: #fbfbff;
  border: 1px solid #f5f5ff;
}
