.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.redCircle {
  background-color: #d84560;
}

.greenCircle {
  background-color: #29cc39;
}

.blueCircle {
  background-color: #2f2a89;
}

.logItemContainer {
  background-color: #f8f8fc;
  border-radius: 12px;
}

.ventricleLine {
  position: relative;
}

.ventricleLine::before {
  content: "";
  width: 4px;
  height: 160%;
  background-color: #f3f3f3;
  position: absolute;
  top: 23px;
  left: 13.5px;
}
